.body {
  border: 1px solid #2a5e6c;
  width: 100%;
  /* height: fit-content; */
  background-image: url("https://aiplexantipiracy.com/_next/static/media/ELEMENT-7.eb8d9321.png");
  background-position-x: 0px, 100%;
  background-position-y: 0px, 100%;
  background-repeat: no-repeat;
  background-size: 40vw 50vh;
}
.borderTop {
  width: 100%;
  /* height: 2.5vh; */
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: #75beca;
  position: relative;
  top: 0;
}
.logoBox {
  display: flex;
  justify-content: space-between;
  /* height: 15vh; */
  box-sizing: border-box;
  /* margin: 0 5vw 0 5vw; */
}
.SunstoneLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/Group_20220513073721_0.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  /* width: 250px; */
  aspect-ratio: 4/2;
  background-position: center;
}
.AiplexLogo {
  /* width: 270px; */
  aspect-ratio: 4/2;
  background-image: url("../images/AIPLEXLOGO.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
/* .responseManagementContainer {
  padding: 0 5vw 0 5vw;
} */
.responseManagementHeading {
  font-size: 50px;
  /* margin-bottom: 30px; */
}
#firstLetter {
  color: red;
}
.responseManagementSocialCounts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.responseManagementSocialCounts > .socialMedia {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 20%;
  margin-bottom: 20px;
}
#verticalBorder {
  border-right: 2px solid rgb(86, 195, 228);
}
