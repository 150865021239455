.avatar_container {
  background-color: transparent;
  border: solid 1px #a0bfc9;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
}

.add_account_icons {
  display: flex;
  justify-content: end;
}
