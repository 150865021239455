.signin-header {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2a5e6c;
}

.login-box {
  padding: 12% 3% 8% 3%;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--white);
  opacity: 0.8;
}

.loginPageContainer {
  background-image: url("../../components/backgroundImages/light_background_img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: content-box;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  /* filter: brightness(90%); */
}

.logoContainer {
  width: 13%;
  height: 9%;
  /* filter: brightness(200%); */
  /* filter: contrast(200%); */
}

@media screen and (max-width: 900px) {
  .logoContainer {
    width: 25%;
    height: 20%;
    filter: contrast(200%);
  }
}
