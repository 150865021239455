/* Close buttons in the dialog forms */
.closebtn {
  color: #2a5e6c;
  cursor: pointer;
}
.closebtn:active {
  color: #f14b1d;
}
.closebtn:hover {
  color: #ff5500;
  transform: scale(1.1);
  transition: 50ms;
}

/* Dailog first letter */
.span_first_letter {
  color: #f14b1d;
}

/* Dailog text  */
.dailogtext {
  color: #2a5e6c;
  text-align: center !important;
}
