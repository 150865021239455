.datePickerContainer {
  width: fit-content;
  display: flex;
  /* margin-left: 20px;
  margin-right: 20px; */
  /* width: 256px;
  height: 36px;
  margin: 0 312px 2px 9px; */
}

/* .datePickerFromTo {
  width: 109px;
  height: 35px;
  margin: 0 11px 1px 0;
  padding: 9px 22.5px 9px 15px;
  border-radius: 3px;
  border: solid 1px #eaeaea;
  background-color: white;
}

.to {
  width: 12px;
  height: 17px;
  margin: 10px 15px 9px 11px;
  font-family: BookAntiqua;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 6;
  letter-spacing: normal;
  text-align: left;
  color: #2a5e6c;
}

.fromdate {
  width: 33px;
  height: 17px;
  margin: 0 29.5px 0 0;
  font-family: BookAntiqua;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 6;
  letter-spacing: normal;
  text-align: left;
  color: #2a5e6c;
}

.todate {
  width: 16px;
  height: 17px;
  margin: 0 65.5px 0 0;
  font-family: BookAntiqua;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 6;
  letter-spacing: normal;
  text-align: left;
  color: #2a5e6c;
} */
