.all_text {
  text-align: left;
  margin-inline: 280px;
}

/* th {
  text-align: center;
  background-color: #2a5e6c;
  color: white;
} */

.table_third {
  background-color: rgb(186 186 201);
  /* border: ; */
}

.t_body_third {
  border: solid 1px black !important;
  background-color: rgb(255, 255, 255) !important;
}
