/* .scrollable::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #85bac6;
  position: absolute;
} */

.scrollable {
  overflow: auto;
  overflow-x: hidden;
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #b0d2da;
  border-radius: 3px;
}

.scrollable:hover::-webkit-scrollbar-thumb {
  background-color: #85bac6;
}

.sc .scrollable::-webkit-scrollbar-track {
  background-color: transparent;
}
.two-line-paragraph {
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.selectField:focus {
  border: "0px solid red";
}

.textAreaFiled {
  outline: 1px #a0bfc9;
  border: 1px solid #cecece;
  color: #2a5e6c;
  border-radius: 5px;
}

.textAreaFiled::placeholder {
  color: #cecece;
}
.textAreaFiled:hover {
  border: 1px solid #2a5e6c;
}

.textAreaFiled:focus {
  border: 1px solid #2a5e6c;
  transition: 0.2s;
}
