.welcome_text {
  font-size: 50px;
  color: #d9b869;
}

.left_music {
  position: absolute;
  top: 25%;
  left: 0%;
}

.right_music {
  position: absolute;
  bottom: 30%;
  right: 0%;
}

.bottom_container {
  position: absolute;
  bottom: 0%;
  padding: 0;
  margin: 0;
}

.bottom_text {
  width: 100%;
  height: 100%;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  padding: 0;
  margin: 0;
}

.a3logo {
  width: 3%;
  aspect-ratio: 4/3;
  /* height: 100px; */
}

.center_logo {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
}

.center_image {
  width: 300px;
}

@media (max-width: 1500px) {
  .welcome_text {
    font-size: 35px;
  }

  /* .logo {
      width: 70px;
      height: 125px;
    } */

  .center_image {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .welcome_text {
    font-size: 30px;
  }

  /* .logo {
      width: 40px;
      height: 70px;
    } */

  .center_image {
    width: 30%;
  }
}
