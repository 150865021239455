.circular_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 92px;
  background-color: #e0e2e6;
  border-radius: 40%;
  padding: 10px;
  color: #000000;
  /* overflow: hidden; */
}

.circular_border {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  border: 6px solid #fabbac;
  margin: auto;
  background-color: #e0e2e6;
}

.circular_shadow {
  height: 113px;
  width: 113px;
  border-radius: 50%;
  border: 6px solid #e0e2e6;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
