/* No data found */
.nodataFoundContainer {
  width: 100%;
  padding-top: 20px;
}

.nodataFoundText {
  font-size: 28px;
  text-align: center;
  color: #2a5e6c;
  font-weight: bold;
}
