.droppable-element {
  width: 200px;
  text-align: center;
  background: #e8f5e9;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

Add this to your CSS .react-grid-item:hover .react-resizable-handle,
.react-grid-item:focus .react-resizable-handle {
  display: block !important;
}

/* Set the default visibility to hidden */
.react-resizable-handle {
  display: none !important;
}

/* Set the default visibility to hidden */
.react-resizable-handle {
  display: none !important;
}

/* Show/hide based on window pathname */
body[data-dashboard="true"] .react-grid-item:hover .react-resizable-handle,
body[data-dashboard="true"] .react-grid-item:focus .react-resizable-handle {
  display: block !important;
}
