.wrapper {
  margin-left: 0px !important;
  margin-top: 0.8%;
  margin-bottom: 0.8%;
  margin-right: 0px !important;
}
.dashboard-title::first-letter {
  color: #e64b25;
}

.dashboard-title {
  padding: 5px;
  margin-bottom: 0;
}

.sc-breuTD {
  width: 400px;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 30px;
}

.working_on {
  color: #2a5e6c;
  font-size: 14px;
}
@media screen and (max-width: 900px) {
  .logoHeader {
    height: 60px;
    width: auto;
  }
}
