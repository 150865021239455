.imageContainer {
  width: 160px;
  height: 158px;
  border-radius: 50% !important;
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(2, 2, 2, 0.16);
  border: solid 8px #a0bfc9;
  background-color: var(--white);
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledContainer {
  width: 160px;
  height: 158px;
  object-fit: contain;
  background-image: url("../../Images//WelcomePage/Ellipse\ 16@3x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  content: url("../../Images/WelcomePage/app_logo.webp");
  position: absolute;
}

.brand_right {
  content: url("../../Images/WelcomePage/brand_right.png");
}

.social {
  content: url("../../Images/WelcomePage/social_listening.png");
}

.response {
  content: url("../../Images/WelcomePage/response_management.png");
}

.review {
  content: url("../../Images/WelcomePage/review_management.png");
}

.customer_experience {
  content: url("../../Images/WelcomePage/customer_exp.png");
}

.settings {
  content: url("../../Images/WelcomePage/settings.png");
}

.title::first-letter {
  color: rgb(255, 0, 0);
}
