/* Post circle button */
.buttonContainer :hover {
  background-color: #2a5e6c;
  /* height: 40px;
  width: 40px;
  border-radius: 40px; */

  /* background-color: "red"; */
  /* padding: 20px 60px; */
  /* width: 40px;

  /* color: #f35c20;
  background-color: rgb(255, 67, 67); */
  /* width: fit-content;
  position: fixed;
 
  bottom: 40px;
  right: 30px;
 


  text-align: center; */
  /* box-shadow: 2px 2px 3px #999; */
  /* border: 2px solid #2a5e6c;
  z-index: 100; */

  /* position: right;

  /* border-radius: 50px;
  border: none;
  color: white;
  padding: 12px 16px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  z-index: 100;  */
}
