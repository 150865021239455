.labelFieldBox {
  display: flex;
  align-items: center;
}

.ticket_desc_test_area {
  width: 100%;
  border: 1px solid #cccbcb;
  border-radius: 5px;
  text-align: justify;
}

.ticket_desc_test_area:focus {
  outline: 0px solid #a0bfc9;
}

.ticket_desc_test_area:hover {
  border: 1px solid #2a5e6c;
}

.ticket_desc_test_area_error {
  width: 100%;
  border: 1px solid #d32f2f;
  border-radius: 5px;
}

.ticket_desc_test_area_error:hover {
  border: 1px solid #d32f2f;
}

.ticket_desc_test_area_error:focus {
  border: 1px solid #a0bfc9;
  outline: 1px solid #a0bfc9;
}

.ticket_desc_test_area ::placeholder {
  background-color: #02151b;
}

textarea::placeholder {
  color: #9db5bb;
  padding: 10px;
  margin: 10px;
}
