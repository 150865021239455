.shake-animation {
  animation: shake 0.4s ease infinite; /* Apply the shake animation */
}
table th {
  border-top: 1px solid transparent;
  border-right: 1px solid #e4ecef;
  border-left: 1px solid #e4ecef;
  border-bottom: 1px solid transparent;
}

table td {
  border-top: 1px solid transparent;
  border-right: 1px solid #e4ecef;
  border-left: 1px solid #e4ecef;
  border-bottom: 1px solid transparent;
}
