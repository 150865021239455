/* .css-wu6b02-MuiTypography-root { */
/* padding-right: 10px !important; */
/* } */

/* title and review */
/* .css-1ntlauu-MuiListItem-root { */
/* padding-left: 0px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}*/

/* List items */
.list_item {
  padding-top: 0 !important;
}

.title_review {
  font-weight: 700;
}

/* divider */
.divider {
  border-width: 2px !important;
  margin-top: 5px !important;
}

/*status & ticket button */
.btn {
  border-radius: 23px !important;
  background-color: #2a5e6c !important;
  color: white !important;
}

.feed_box {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
}
