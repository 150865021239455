.TicketBox {
  border-radius: 9px;
  border: solid 2px #f3f9fb;
  text-align: center;
  color: #2a5e6c;
  background-color: white;
  box-shadow: inset 0 -17px 66px 0 #f3f9fb;
  font-size: 24px;
  width: 246px;
  height: 161px;
}

.ResolvedTicketBox {
  color: #4be056;
  border-radius: 9px;
  border: solid 2px #f3f9fb;
  text-align: center;
  background-color: white;
  box-shadow: inset 0 -17px 66px 0 #f3f9fb;
  font-size: 24px;
  width: 246px;
  height: 161px;
}

.PendingTicketBox {
  color: #f79f8a;
  border-radius: 9px;
  border: solid 2px #f3f9fb;
  text-align: center;
  background-color: white;
  box-shadow: inset 0 -17px 66px 0 #f3f9fb;
  font-size: 24px;
  width: 246px;
  height: 161px;
}

.firstLetter {
  color: #e64b25;
}
.elipseBox {
  background-image: url("../../../components/backgroundImages/Ellipse\ 6.svg");
  min-width: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 55px;
}
.horizontalRule {
  border-bottom: solid 1px #e5e2e2;
}
.brandMetricsBoxes {
  border: solid 1px #a0bfc9;
  border-radius: 13px;
  width: 469px;
  height: 223px;
}

.netPromoterBoxes {
  background-image: url("../../../components/backgroundImages/Rectangle\ 880.svg");
  min-width: 210px;
  min-height: 295px;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.humanIconBox {
  /* background-color: gray; */
  width: 84px;
  height: 70px;

  display: flex;
  /* align-items: flex-start; */
  justify-content: flex-start;
}
.HumanIcon {
  background-image: url("../../../components/backgroundImages/Icon\ ionic-ios-man.svg");
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}

.grid-container-one {
  display: grid;
  /* background-color: #4be056; */
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
  /* set a minimum height for the container */
  /* min-height: 300px; */
  /* specify the grid row heights as a fraction of the container's minimum height */
  grid-template-rows: 1fr;
}

.grid-container {
  display: grid;
  /* background-color: #4be056; */
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  /* set a minimum height for the container */
  /* min-height: 300px; */
  /* specify the grid row heights as a fraction of the container's minimum height */
  grid-template-rows: 1fr;
}

.grid-container-two {
  display: grid;
  /* background-color: #4be056; */
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
  /* set a minimum height for the container */
  /* min-height: 300px; */
  /* specify the grid row heights as a fraction of the container's minimum height */
  grid-template-rows: 1fr 1fr;
}

.grid-container-three {
  display: grid;
  /* background-color: #4be056; */
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(3, 3fr);
  gap: 10px;
  /* set a minimum height for the container */
  /* min-height: 300px; */
  /* specify the grid row heights as a fraction of the container's minimum height */
  grid-template-rows: 1fr 1fr;
}

.grid-item {
  /* add styles for the grid items */
  border: 1px solid rgba(123, 123, 123, 0.59);
  width: 100%;
  padding: 10px;
  /* set the height of the grid item to 100% of its parent's height */
  height: 100%;
}
