.all_text {
  text-align: left;

  margin-inline: 280px;
}

.orm_pdf_table tr {
  border: 1px solid rgb(154, 154, 154);
}

/* .orm_pdf_table tr:nth-child(all) {
  background: linear-gradient(#5ebf5d 0%, #ff2020 100%);
} */

/* .orm_pdf_table tr:last-child {
  background: linear-gradient(to top, #75ff60 0%, #ff4747d2 100%);
} */

.orm_pdf_table th {
  width: 100px;
  background-color: rgb(154, 197, 223);
  color: rgb(41, 41, 41);
}

.orm_pdf_table table {
  text-align: center;
}

/* .percentage_column {
  background-image: linear-gradient(red, rgb(47, 255, 0));
} */
