/* Close buttons in the dialog forms */
.closebtn {
  color: #2a5e6c;
  cursor: pointer;
}
.closebtn:active {
  color: #f14b1d;
}

/* Dailog first letter */
.span_first_letter {
  color: #f14b1d;
}

/* Dailog text  */
.dailogtext {
  color: #2a5e6c;
  text-align: center !important;
}
