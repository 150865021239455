.platformReviewscount {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-bottom: 50px; */
}
.platformReviewscount > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#firstLetter {
  color: red;
}
.verticalBorder {
  border-right: 2px solid rgb(86, 195, 228);
}
